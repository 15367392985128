/*
 * If this thing is a number, or a number masquerading as a string,
 * then give it some comma separators. Otherwise, run it through a
 * translate function
 */
function renderPossibleNumber(value, t) {
  if (typeof value === 'number') {
    return value.toLocaleString();
  }
  if (!isNaN(value)) {
    return Number(value).toLocaleString();
  }
  return t(value);
}

/**
 * This is how we return values with units in the regenagri scorecard
 */
function isValueWithUnits(value) {
  return value instanceof Object && value.value !== undefined && value.unit;
}

/**
 * This is one of the two ways that CFT returns values with units (eg ['-3722', 'kg CO2e / kg'])
 */
function isCftValueWithUnitsAsArray(value) {
  return value instanceof Array && value.length === 2 && !isNaN(value[0]);
}

/**
 * This is one of the two ways that CFT returns values with units (eg '1600 litres')
 */
function isCftValueWithUnitsAsString(value) {
  return typeof value === 'string' && isCftValueWithUnitsAsArray(value.split(' '));
}

function renderValueWithUnits({ value, unit }, t) {
  const separator = (unit === '%') ? '' : ' ';
  return `${renderPossibleNumber(value, t)}${separator}${t(unit)}`;
}

function formatValue(value, t = (v) => v) {
  if (typeof value === 'boolean') {
    return t(value ? "yes" : "no");
  }
  if (isValueWithUnits(value)) {
    // The regenagri style
    return renderValueWithUnits(value, t);
  }
  if (isCftValueWithUnitsAsArray(value)) {
    return formatValue({ value: Number(value[0]), unit: value[1] }, t);
  }
  if (isCftValueWithUnitsAsString(value)) {
    const splat = value.split(' ');
    return formatValue({ value: Number(splat[0]), unit: splat[1] }, t);
  }
  if (value instanceof Array) {
    return value.map(val => renderPossibleNumber(val, t)).join(', ');
  }
  return renderPossibleNumber(value, t);
}

function calculateFarmSummary(scorecardResult) {
  const { assessmentDetails } = scorecardResult;

  return {
    totalAreaOfLandBeingAssessed: assessmentDetails?.totalAreaOfLandBeingAssessed,
    totalAgriculturalLand: assessmentDetails?.totalAgriculturalLand,
    totalConservationArea: assessmentDetails?.totalConservationArea,
    amountOfWaterUsedOnFarm: assessmentDetails?.amountOfWaterUsedOnFarm,
    totalAmountOfSyntheticFertilisers: assessmentDetails?.totalAmountOfSyntheticFertilisers,
    totalEnergyUse: assessmentDetails?.totalEnergyUse,
    totalGreenhouseGases: assessmentDetails?.totalEmissions,
  };
}

module.exports = { formatValue, calculateFarmSummary };

import React from 'react';
import { Table } from 'react-bootstrap';
import Translation from 'locales/Translation';
import { formatValue } from './utils';

const { useTranslation } = Translation.setup();

export function formatDetails(value) {
  if (!value || !value.length) {
    return null;
  }
  return value.map(v => {
    const keys = Object.keys(v);
    if (keys.length === 2 && keys.includes('CO2e_emission')) {
      const otherKey = keys.filter(k => k !== 'CO2e_emission')[0];
      return <p key={[otherKey]}><small>{v[otherKey]}: {v.CO2e_emission}</small></p>;
    }
    return null;
  });
}

function ScorecardTableRow({ label, value, t }) {
  if (label === "disaggregation_totals") {
    return null;
  }

  let formattedValue = formatValue(value, t);
  if (label === 'details') {
    formattedValue = formatDetails(value);
  }

  return (
    <tr className="" key={label}>
      <td className="regenagri-scorecard-component-field">{t(label)}</td>
      <td className="regenagri-scorecard-component-value">{formattedValue}</td>
    </tr>
  );
}

export default function ScorecardTable({ obj }) {
  const { t } = useTranslation();
  return (
    <Table responsive striped>
      <tbody>
        { Object.entries(obj).map(([key, value]) => ((key === "name")
          ? null
          : <ScorecardTableRow label={key} value={value} t={t} key={key}/>
        ))}
      </tbody>
    </Table>
  );
}

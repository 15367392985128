import React from 'react';
import { getComponentScoreColour } from 'common/scorecard';
import Translation from 'locales/Translation';
import ScorecardTable from './ScorecardTable';

const { useTranslation } = Translation.setup();

export default function ScorecardComponent({ component }) {
  const { t } = useTranslation();

  const colour = getComponentScoreColour(component);
  return (
    <div className="regenagri-scorecard-section" style={{ borderLeft: `32px solid ${colour}` }} key={component.name}>
      <div className="regenagri-scorecard-section-header">
        <h4> { t(component.name) } </h4>
        <h4 className="regenagri-scorecard-section-score">
          {t('pointsWithCount', { count: component.score })}
        </h4>
      </div>
      { component.params && <ScorecardTable obj={component.params} />}
    </div>
  );
}

import auth from 'common/auth';
import merge from 'deepmerge';
import { buildCombinedScorecardUrl } from 'utils';

const KNOWN_ERRORS = {
  "EMAIL_ALREADY_EXISTS": "An account already exists for this email address.",
  "UNVERIFIED_EMAIL_ADDRESS": "We are very excited by your eagerness to log in, but you haven't verified your email address yet!",
  "BAD_LOGIN_CREDENTIALS": "Oops! We can't seem to match that email and password to an account.",
  "EXPIRED_PASSWORD_RESET_TOKEN": "This password reset request has expired. If you still need to reset your password, please create a new request.",
  "UNRECOGNISED_TOKEN": "The supplied token was not recognised.",
  "ASSESSMENT_NOT_FOUND_FOR_SUBMISSION": "The submission could not be linked to an existing assessment.",
  "SUBMISSION_EXISTS_FOR_ASSESSMENT": "A submission already exists for this part of the assessment.",
  "UNAUTHORISED": "You are not authorised to perform this action.",
  "INVALID_FARM_NAME": "You must specify a farm name.",
  "USER_NOT_FOUND": "The user specified has not been found.",
  "ADMIN_EMAIL_ADDRESS_NOT_SPECIFIED": "No admin email address was specified.",
  "GROUP_NAME_NOT_SPECIFIED": "No group name was specified.",
  "USER_IS_ALREADY_GROUP_ADMIN": "The group could not be created as that user is already a group administrator.",
  "USER_IS_ALREADY_GROUP_MEMBER": "That user already belongs to a group.",
  "CANNOT_COMPLETE_ANOTHER_USERS_ASSESSMENT": "An assessment can only be completed by the user who created it.",
  "USER_IS_A_GROUP_ADMIN": "That user is already a group admin.",
  "USER_IS_NOT_INVITED_TO_THAT_GROUP": "That invitation link is invalid.",
  "SUBMISSION_NOT_FOUND_FOR_ASSESSMENT": "That submission does not exist for that assessment.",
  "INVALID_FARM_ID": "Invalid farm ID.",
  "INVALID_GROUP_ID": "Invalid group ID.",
  "USER_NOT_FULL_MEMBER": "regenagriSignUpInfo",
  "CANNOT_CLOSE_ASSESSMENT": "The assessment could not be completed. This is probably due to an error when trying to calculate the carbon assessment. Please go back and check the values entered.",
  "INVALID_INPUT_WOULD_CAUSE_SCALING_DOWN": "The assessments could not be combined. The total agricultural land value entered is less than the total for the assessments.",
  "INVALID_EMAIL_ADDRESS": "Email address was invalid.",
};

function cleanFarmName(farm) {
  return { ...farm, name: farm.name.trim() };
}

export function orderForms(forms) {
  const newForms = forms.filter(f => !['fertilisers', 'cpp'].includes(f));
  let i = forms.indexOf('fields') + 1;
  if (forms.includes('fertilisers')) {
    newForms.splice(i, 0, "fertilisers");
    i += 1;
  }
  if (forms.includes('cpp')) {
    newForms.splice(i, 0, "cpp");
    i += 1;
  }
  return newForms;
}

async function detectWhitelistedErrors(res) {
  if (res.status > 399) {
    const resJson = await res.json();

    if (Object.keys(KNOWN_ERRORS).includes(resJson.errorCode)) {
      const error = new Error(KNOWN_ERRORS[resJson.errorCode]);
      error.errorCode = resJson.errorCode;
      throw error;
    }
  }
}

async function makeRequest(path, options, useJwt = false) {
  const defaultOptions = {
    headers: { 'Content-Type': 'application/json' },
  };

  if (useJwt && auth.isAuthenticated()) {
    defaultOptions.headers['x-jwt-token'] = auth.getJwt();
  }

  const res = await fetch(path, merge(defaultOptions, options));

  if (res.status >= 200 && res.status < 400) {
    if (options['Content-Type'] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      return res;
    }
    return res.json();
  }

  await detectWhitelistedErrors(res);

  throw new Error(`An unexpected error occurred`);
}

async function getForm(formId, useJwt) {
  return makeRequest(`/app/form/${formId.toLowerCase()}`, {}, useJwt);
}

async function postForm(formId, data, useJwt) {
  const options = {
    method: 'post',
    body: JSON.stringify(data),
  };

  return makeRequest(`/app/form/${formId.toLowerCase()}/submission`, options, useJwt);
}

async function putForm(formId, submissionId, data, useJwt) {
  const options = {
    method: 'put',
    body: JSON.stringify(data),
  };

  return makeRequest(`/app/form/${formId.toLowerCase()}/submission/${submissionId}`, options, useJwt);
}

function makeCreateAssessmentPayload(farmId, userId, groups) {
  const payload = { farmId };
  if (userId) {
    payload.userId = userId;
  }
  if (groups) {
    payload.groups = groups;
  }
  return payload;
}

async function createAssessment(farmId, userId, groups) {
  const options = {
    method: 'post',
    body: JSON.stringify(makeCreateAssessmentPayload(farmId, userId, groups)),
  };
  return makeRequest(`/app/assessments`, options, true);
}

async function getAssessmentSubmission(formId, assessmentId) {
  return makeRequest(`/app/form/assessments/${formId.toLowerCase()}/submission?assessmentId=${assessmentId}`, {}, true);
}

async function getAssessments(userId) {
  const path = userId ? `/app/assessments?userId=${userId}` : `/app/assessments`;
  return makeRequest(path, {}, true);
}

async function getAssessment(assessmentId) {
  return makeRequest(`/app/assessments/${assessmentId}`, {}, true);
}

async function getScorecard(assessmentId, overrideWarnings) {
  let url = `/app/assessments/scorecard?assessmentId=${assessmentId}`;
  if (overrideWarnings) {
    url += '&overrideWarnings=1';
  }
  return makeRequest(url, {}, true);
}

async function getDownload(assessmentId, overrideWarnings) {
  let url = `/app/assessments/scorecard/download?assessmentId=${assessmentId}`;
  if (overrideWarnings) {
    url += '&overrideWarnings=1';
  }
  return makeRequest(url, { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }, true);
}

async function getScorecardComparison(assessmentIdList) {
  const queryParams = assessmentIdList.join("&assessmentId=");
  return makeRequest(`/app/assessments/scorecard/compare?assessmentId=${queryParams}`, {}, true);
}

async function getCombinedScorecard(
  selectedAssessments,
  farmNames,
  totalAreaOfLandBeingAssessed,
  totalAreaOfLandBeingAssessedUnit,
  totalAgriculturalLand,
  totalAgriculturalLandUnit,
  totalConservationArea,
  totalConservationAreaUnit,
) {
  const url = buildCombinedScorecardUrl(
    '/app/assessments/scorecard/combine?',
    selectedAssessments,
    farmNames,
    totalAreaOfLandBeingAssessed,
    totalAreaOfLandBeingAssessedUnit,
    totalAgriculturalLand,
    totalAgriculturalLandUnit,
    totalConservationArea,
    totalConservationAreaUnit,
  );
  return makeRequest(url, {}, true);
}

async function listAllUsers(limit, skip) {
  return makeRequest(`/app/users?limit=${limit}&skip=${skip}`, {}, true);
}

async function listGroupUsers(groupId, limit, skip) {
  return makeRequest(`/app/groups/${groupId}/users?limit=${limit}&skip=${skip}`, {}, true);
}

async function upgradeUser(userId) {
  const options = {
    method: 'post',
    body: JSON.stringify({ userId }),
  };

  return makeRequest(`/app/fullmembers`, options, true);
}

async function getAssessmentFormIdsAsync(assessmentId) {
  const { forms } = await makeRequest(`/app/assessments/forms?assessmentId=${assessmentId}`, {}, true);
  return orderForms(forms);
}

async function createFarm(farmName, userId) {
  const options = {
    method: 'post',
    body: JSON.stringify({ farmName, userId }),
  };
  return makeRequest(`/app/farms`, options, true);
}

function transformFarmsResponse(farmsData) {
  const cleanedFarms = farmsData.farms.map(cleanFarmName);
  return { farms: cleanedFarms };
}

async function getUserFarms(userId) {
  const query = userId ? `?userId=${userId}` : '';
  const url = `/app/farms${query}`;
  const farmsData = await makeRequest(url, {}, true);
  return transformFarmsResponse(farmsData);
}

async function getUserById(userId) {
  return makeRequest(`/app/users/${userId}`, {}, true);
}

async function createGroup(groupName, adminEmail) {
  const options = {
    method: 'post',
    body: JSON.stringify({ groupName, adminEmail }),
  };
  return makeRequest(`/app/groups`, options, true);
}

async function inviteUser(groupId, emailAddress, inviteAsAdmin = false) {
  const options = {
    method: 'post',
    body: JSON.stringify({ emailAddress, inviteAsAdmin }),
  };
  return makeRequest(`/app/groups/${groupId}/invitations`, options, true);
}

async function verifyInvitation(groupId, userId) {
  const options = {
    method: 'post',
    body: JSON.stringify({ userId }),
  };
  return makeRequest(`/app/groups/${groupId}/verify-invitation`, options, true);
}

async function removeUser(groupId, userId) {
  const options = {
    method: 'delete',
  };
  return makeRequest(`/app/groups/${groupId}/users/${userId}`, options, true);
}

async function listUserGroups(userId) {
  return makeRequest(`/app/users/${userId}/groups`, {}, true);
}

async function listGroupAssessments(groupId) {
  return makeRequest(`/app/groups/${groupId}/assessments`, {}, true);
}

async function changeAssessmentGroup(assessmentId, groups) {
  const options = {
    method: 'PATCH',
    body: JSON.stringify({ groups }),
  };
  return makeRequest(`/app/assessments/${assessmentId}/group-id`, options, true);
}

async function getFarmForAssessment(assessmentId) {
  return makeRequest(`/app/assessments/${assessmentId}/farm`, {}, true);
}

async function reopenAssessment(assessmentId) {
  const options = {
    method: 'POST',
  };
  return makeRequest(`/app/assessments/${assessmentId}/reopen`, options, true);
}

async function createUser(email) {
  return postForm('preregister', { data: { email } }, false);
}

async function getGroups() {
  return makeRequest(`/app/groups/`, {}, true);
}

const exports = {
  changeAssessmentGroup,
  createAssessment,
  createFarm,
  createGroup,
  createUser,
  getAssessment,
  getAssessmentFormIdsAsync,
  getAssessments,
  getAssessmentSubmission,
  getCombinedScorecard,
  getFarmForAssessment,
  getForm,
  getGroups,
  getScorecard,
  getScorecardComparison,
  getUserById,
  getUserFarms,
  inviteUser,
  listAllUsers,
  listGroupAssessments,
  listGroupUsers,
  listUserGroups,
  makeCreateAssessmentPayload,
  postForm,
  putForm,
  removeUser,
  reopenAssessment,
  transformFarmsResponse,
  upgradeUser,
  verifyInvitation,
  getDownload,
};
export default exports;
